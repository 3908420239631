@import "src/styles/modules/colours";
@import "src/styles/modules/typography";
@import "src/styles/modules/breakpoints";

.menu-link {
  display: block;
  background-color: transparent;
  font: 6vw $brandSerifFont;
  color: $white;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 0;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  @media(max-width: $mobile) {
    font-size: 12vw;
    margin: 2vh auto;
  }
}
