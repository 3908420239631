@import "src/styles/modules/colours";
@import "src/styles/modules/typography";
@import "src/styles/modules/breakpoints";

html,
body {
  background-color: $white;
  font: 12px $brandSansFont;
  color: $black;
  margin: 0;
  padding: 0;
}

a {
  color: $black;

  &:hover {
    opacity: 0.5;
  }
}

ul {
  margin: 0;
  padding: 0 0 0 15px;
}

li {
  margin: 0;
  padding: 0;
}

._desktop {
  display: block;

  @media(max-width: $mobile) {
    display: none;
  }
}

._mobile {
  display: none;

  @media(max-width: $mobile) {
    display: block;
  }
}
