@import "src/styles/modules/dimensions";
@import "src/styles/modules/typography";

.stage-template_content {
  position: absolute;
  top: calc(150px + 10vh);
  left: 120px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: calc(100% - (120px + var(--pageMargin)));
  height: calc(100% - 60vh);
  min-height: 250px;
  font-family: $brandSerifFont;

  >div {
    &:first-child {
      min-width: 500px;
    }

    &:last-child {
      position: relative;
    }
  }

  video {
    position: absolute;
    top: -2vh;
    left: 50%;
    height: 110%;
    transform: translateX(-50%);
  }
}

.stage-template_text {
  display: grid;
  grid-template-columns: 1fr;

  >div {
    z-index: 10;

    &:first-child {
      font-size: 4.25vw;
      line-height: 0.9em;
      text-transform: uppercase;
    }

    &:last-child {
      position: relative;
      font-size: calc(4px + 1.9vw);
      line-height: 1.1em;

      >div {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        @media(max-width: 1200px) {
          width: 75%;
        }

        @media(max-width: 800px) {
          width: 50%;
        }
      }
    }
  }
}

.stage-template_content-mobile {
  position: absolute;
  display: grid;
  grid-auto-rows: fit-content(100px);
  top: calc(150px + 5vh);
  left: 50%;
  width: 90%;
  height: 80vh;
  font-family: $brandSerifFont;
  transform: translateX(-50%);

  >div {
    &:first-child {
      font-size: 8.5vw;
      line-height: 0.9em;
      text: {
        align: center;
        transform: uppercase;
      }
    }

    &:nth-child(2) {
      text-align: center;
      padding: 3vh 0;
    }

    &:last-child {
      font-size: 4.1vw;
    }
  }

  video {
    width: auto;
    height: 35vh;
  }
}
