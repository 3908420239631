@import "src/styles/modules/colours";
@import "src/styles/modules/breakpoints";

.video_container {
  position: fixed;
  top: 10vh;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $white;

  >div {
    position: relative;
    width: 100%;
    height: 80%;
  }
}

.video_media {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  transform: translate(-50%, -50%);

  @media(max-width: $mobile) {
    top: 40%;
  }
}
