@import "src/styles/modules/dimensions";
@import "src/styles/modules/breakpoints";

.clients {
  position: absolute;
  top: calc(100px + 20vh);
  left: 50%;
  width: calc(100% - (2 * var(--pageMargin)));
  font-size: 2.5vw;
  transform: translateX(-50%);

  @media(max-width: $mobile) {
    font-size: 4.8vw;
  }
}
