@import "src/styles/modules/dimensions";
@import "src/styles/modules/breakpoints";

.stages_progress- {
  &desktop {
    position: absolute;
    top: calc(120px + 15vh);
    left: var(--pageMargin);
    height: 58vh;
    z-index: 80;
  }

  &mobile {
    position: absolute;
    top: calc(100px + 6vh);
    left: 50%;
    width: 40vw;
    transform: translateX(-50%);
    z-index: 80;
  }
}

.stage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
