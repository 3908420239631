@import "src/styles/modules/typography";
@import "src/styles/modules/dimensions";
@import "src/styles/modules/breakpoints";

.panel-title {
  position: fixed;
  top: calc(48px + 4.2vh);
  left: calc(var(--pageMargin) + 12vw);
  font: 2.7vw $brandSerifFont;
  line-height: 1.2em;
  text-transform: uppercase;
  z-index: $zPanelTitle;

  @media(max-width: $mobile) {
    top: calc(48px + 6.5vh);
    left: 27.5vw;
    font-size: 5.5vw;
  }
}
