@import "src/styles/modules/colours";
@import "src/styles/modules/typography";

.stage-progress_container- {
  &portrait {
    position: relative;
    height: 100%;
  }

  &landscape {
    position: relative;
    width: 100%;
  }
}

.stage-progress_ {
  &portrait,
  &landscape {
    position: absolute;
    display: grid;
    top: 0;
    left: 0;
    font: 16px $brandSerifFont;
    text-align: center;
    z-index: 10;

    >div {
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &portrait {
    grid-template-columns: 1fr;
    width: 30px;
    height: 100%;

    >div {
      height: 20%;
    }
  }

  &landscape {
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
  }
}

.stage-progress_marker- {
  &portrait,
  &landscape {
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: $black;
    border-radius: 50%;
    z-index: 5;
  }

  &portrait {
    top: 0;
  }

  &landscape {
    top: -9px;
  }
}
