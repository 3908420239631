@import "src/styles/modules/colours";
@import "src/styles/modules/dimensions";
@import "src/styles/modules/breakpoints";

.privacy-policy {
  position: fixed;
  top: 100vh;
  left: 0;
  height: 80vh;
  background-color: $white;
  font-size: 2vh;
  box-shadow: $panelShadow;
  z-index: $zPrivacyPolicy;

  @media(max-width: $mobile) {
    font-size: 1.3vh;
  }
}

.privacy-policy_container {
  position: relative;
  height: 100%;
  padding: 50px var(--pageMargin);
}

.privacy-policy_close {
  position: absolute;
  top: 0;
  right: var(--pageMargin);
  font-size: 40px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}
