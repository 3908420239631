@import "src/styles/modules/colours";
@import "src/styles/modules/dimensions";
@import "src/styles/modules/breakpoints";

.menu-button {
  position: fixed;
  top: calc(42px + 3.5vh);
  right: 2.8vw;
  z-index: $zMenuButton;

  @media(max-width: $mobile) {
    top: calc(40px + 6.3vh);
    right: 1.7vw;
  }
}

.menu-button_icon {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: $black;
  border-radius: 50%;
  transform: scale(0.7);
  transition: background-color 0.5s ease-in-out;
  cursor: pointer;

  .menu-button_checkbox {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;
    -webkit-touch-callout: none;
    opacity: 0;
  }

  div {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 22px;
    height: 12px;
  }

  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $white;
    border-radius: 1px;
    transition: all 0.5s cubic-bezier(0.1, 0.82, 0.76, 0.965);

    &:first-of-type {
      top: 0;
    }
    &:last-of-type {
      bottom: 0;
    }
  }

  &.active,
  .menu-button_checkbox:checked + div {
    span {
      &:first-of-type {
        transform: rotate(45deg);
        top: 5px;
      }

      &:last-of-type {
        transform: rotate(-45deg);
        bottom: 5px;
      }
    }
  }

  &.active:hover span:first-of-type,
  &.active:hover span:last-of-type,
  &:hover .menu-button_checkbox:checked + div span:first-of-type,
  &:hover .menu-button_checkbox:checked + div span:last-of-type {
    width: 22px;
  }

  &:hover {
    @media (min-width: $mobile) {
      span:first-of-type {
        width: 26px;
      }

      span:last-of-type {
        width: 12px;
      }
    }
  }
}

