@import "src/styles/modules/colours";
@import "src/styles/modules/dimensions";

.panel_container {
  position: fixed;
  top: 100vh;
  left: 0;
  width: 100%;
  height: 120vh;
  box-shadow: $panelShadow;
}

.panel_content {
  height: calc(100vh - (#{$panelEdgeHeight} + #{$panelEdgeTopMargin}));
  padding: 0 var(--pageMargin);
}
