@import "src/styles/modules/colours";
@import "src/styles/modules/typography";
@import "src/styles/modules/dimensions";
@import "src/styles/modules/breakpoints";

.menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $black;
  color: $white;
  z-index: $zMenu;
  opacity: 0;
}

.menu_items-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.menu_items {
  position: absolute;
  top: 50%;
  left: 50%;
  width: fit-content;
  transform: translate(-50%, -50%);
}
