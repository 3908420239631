/* PAGE */
$pageMargin: 45px;
$pageMarginMobile: 20px;

@media(min-width: 0) {
  :root {
    --pageMargin: #{$pageMarginMobile};
  }
}

@media(min-width: 641px) {
  :root {
    --pageMargin: #{$pageMargin};
  }
}

/* PANELS */
$panelEdgeHeight: 50px;
$panelEdgeTopMargin: 20px;
$panelEdgeHoleMargin: 30px;
$panelEdgeHoleMarginMobile: 5px;

@media(min-width: 0) {
  :root {
    --panelEdgeHoleMargin: #{$panelEdgeHoleMarginMobile};
  }
}

@media(min-width: 641px) {
  :root {
    --panelEdgeHoleMargin: #{$panelEdgeHoleMargin};
  }
}

/* Z-INDICES */
$zLoader: 150;
$zMenuButton: 110;
$zMenu: 100;
$zLogo: 90;
$zPanelTitle: 85;
$zPrivacyPolicy: 80;
