@import "src/styles/modules/colours";
@import "src/styles/modules/dimensions";
@import "src/styles/modules/breakpoints";

.contact_title {
  top: 80px;
  left: var(--pageMargin);
}

.contact_content {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  top: calc(100px + 15vh);
  left: 50%;
  width: calc(100% - (2 * var(--pageMargin)));
  transform: translateX(-50%);

  @media(max-width: $mobile) {
    top: calc(100px + 20vh);
    grid-template-columns: 1fr;
  }

  >div {
    &:first-child {
      font-size: 2.5vw;

      @media(max-width: $mobile) {
        font-size: 4vw
      }
    }
  }
}

.contact_banking {
  width: fit-content;
  font-size: 1.3vw;
  line-height: 1em;
  margin: 1vh 0 0 auto;

  @media(max-width: $mobile) {
    font-size: 3vw;
    line-height: 1.1em;
    margin: 20px auto 0 0;
  }
}

.contact_privacy-link {
  background-color: transparent;
  font-size: 1.3vw;
  color: $black;
  text: {
    transform: uppercase;
    decoration: underline;
  }
  padding: 0;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  @media(max-width: $mobile) {
    font-size: 2.5vw;
  }
}

.lntpa-badge {
  font-size: 1em;
  margin: 80px 0 0;

  @media(max-width: $mobile) {
    font-size: 3vw;
    margin: 0 0 20px;
  }

  a {
    display: block;
  }

  img {
    width: 8vw;
    margin-top: 5px;

    @media(max-width: $mobile) {
      width: 15vw;
    }
  }
}
