@font-face {
  font-family: "Karelia";
  src: url("../../assets/fonts/karelia/KareliaWeb-Regular.woff2") format("woff2"),
  url("../../assets/fonts/karelia/KareliaWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Pondre Light";
  src: url("../../assets/fonts/pondre/pondre.eot");
  src: url("../../assets/fonts/pondre/pondre.eot?#iefix") format("embedded-opentype"),
  url("../../assets/fonts/pondre/pondre.woff2") format("woff2"),
  url("../../assets/fonts/pondre/pondre.woff") format("woff"),
  url("../../assets/fonts/pondre/pondre.ttf") format("truetype"),
  url("../../assets/fonts/pondre/pondre.svg#Pondre") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$brandSerifFont: "Pondre Light", "Times New Roman", serif;
$brandSansFont: "Karelia", "Helvetica", "Arial", sans_serif;
