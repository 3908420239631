@import "src/styles/modules/dimensions";
@import "src/styles/modules/breakpoints";

.panel-edge {
  display: grid;
  grid-template-columns: var(--panelEdgeHoleMargin) repeat(2, fit-content(50px)) 1fr fit-content(100px) 1fr repeat(2, fit-content(50px)) var(--panelEdgeHoleMargin);
  column-gap: 0;
  width: 100%;
  height: calc(#{$panelEdgeHeight} - 1px);

  @media(max-width: $mobile) {
    grid-template-columns:var(--panelEdgeHoleMargin) fit-content(55px) 1fr fit-content(100px) 1fr fit-content(55px) var(--panelEdgeHoleMargin);
  }
}

.panel-edge_desktop-hole {
  display: block;

  @media(max-width: $mobile) {
    display: none;
  }
}

.panel-edge_top-margin {
  height: $panelEdgeTopMargin;
}
