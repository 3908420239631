@import "src/styles/modules/colours";
@import "src/styles/modules/typography";

.holding {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: $black;
  font: 5vh $brandSerifFont;
  color: $white;
  text-transform: uppercase;

  >div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%, -50%);
  }
}
