@import "src/styles/modules/colours";
@import "src/styles/modules/dimensions";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $white;
  z-index: $zLoader;
}
