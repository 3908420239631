@import "src/styles/modules/colours";
@import "src/styles/modules/breakpoints";

.inline-video {
  display: inline-block;
  width: 10vw;
  height: 2.9vw;
  background-color: $black;
  vertical-align: -0.3vw;
  border-radius: 1.5vw;
  overflow: hidden;

  @media(max-width: $mobile) {
    width: 22vw;
    height: 6.5vw;
    vertical-align: -0.8vh;
    border-radius: 3.5vw;
  }

  >div {
    position: relative;
    width: 100%;
    text-align: center;

    video {
      position: absolute;
      top: 0.3vh;
      left: 50%;
      width: 80%;
      transform: translateX(-50%);
    }
  }

  &._trigger {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}
