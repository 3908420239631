@import "src/styles/modules/dimensions";
@import "src/styles/modules/breakpoints";

.logo {
  position: fixed;
  top: calc(50px + 4.2vh);
  left: var(--pageMargin);
  opacity: 0;
  cursor: pointer;
  z-index: $zLogo;

  @media(max-width: $mobile) {
    top: calc(50px + 6.6vh);
  }

  svg {
    width: 10vw;
    transition: fill 0.5s ease-in-out;

    @media(max-width: $mobile) {
      width: 20vw;
    }
  }
}
