.panel-hole {
  position: relative;

  svg {
    height: 50px;
  }

  &_mask {
    position: absolute;
    top: 0;
    width: 5px;
    height: 50px;

    &:first-child {
      left: -3px;
    }

    &:last-child {
      right: -3px;
    }
  }
}
