@import "src/styles/modules/dimensions";
@import "src/styles/modules/breakpoints";

.introduction_container {
  position: relative;
  width: 100%;
  height: 100%;

  >div {
    position: absolute;
    top: calc(100px + 15vh);
    left: 50%;
    width: 100%;
    font-size: 2.5vw;
    transform: translateX(-50%);

    @media(max-width: $mobile) {
      top: calc(100px + 10vh);
      font-size: 4.8vw;
    }
  }
}
